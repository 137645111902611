.paywall_wrapper {
    width: 100%;
    height: 100%;
    padding: 16px;
    font-family: "VK Sans Display", sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
}

.paywall_heading {
    display: flex;
    align-items: center;
    flex-direction: column;

}

.paywall_heading h2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 17.6px */
    letter-spacing: -0.48px;
}

.paywall_heading h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 26.4px */
    letter-spacing: -0.72px;
    background: linear-gradient(0deg, #F44E17, #FFAC00 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; /* Делаем текст прозрачным */
}

.paywall_router_details {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    width: 259px;
}
.paywall_router_details p {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 0px 13.3px rgba(255, 255, 255, 0.88);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 17.6px */
    letter-spacing: -0.48px;
}

.paywall_btn {
    position: relative;
    bottom: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
}

.paywall_btn button {
    display: flex;
    width: 100%;
    height: var(--Size-Height-controlHeightXL, 60px);
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-shrink: 0;
    border-radius: 8px;
    background: #FFF;
    color: black;
}

.paywall_btn p {
    color: var(--Text-colorTextQuaternary, rgba(255, 255, 255, 0.50));
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
}