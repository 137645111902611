.push_wallet_connect {
    transform: translate(-50%, -50%) !important;
    left: 50%;
    z-index: 10000;
    align-items: center;
    justify-content: center;
    min-width: 180px; 
    border-radius: 8px;
    background: var(--Background-colorBgElevated, #16181A);
    display: flex;
    height: var(--Size-Height-controlHeightL, 48px);
    padding: 0 16px;
    position: absolute;
    z-index: 100;
    top: -54px;
    gap: 12px;
    animation: 2.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) notification 1;
    transition: 2s all;

}

.push_wallet_connect > p {
    color: var(--Text-colorTextPrimary, #FFF);
    font-family: "VK Sans Display", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 175% */
}

@keyframes notification {
    0% {
        top: 0;
        transform: scale(1);
    }
    80% {
        top: 80px;

    }
    100% {
        /*top: 100px;*/
    }
}
