.timer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "VK Sans Display", sans-serif;
    color: #333;
}

.timer p {
    text-align: center;
    word-wrap: break-word;
    word-break: break-word;
    /* добавляем, если поддерживается */
    overflow-wrap: break-word;
    /*margin-bottom: 30px;*/
}

.timer_circle {
    transform: rotate(-90deg);
}

.timer_text {
    /*position: absolute;*/
    font-size: 24px;
    font-weight: bold;
    color: #eb8b0c;
}
