.vpn {
    /*padding-top: 100px;*/
    /*padding-bottom: 16px;*/
    padding: 16px 16px 0px 16px;
    display: flex;
    justify-content: flex-start;
    /*justify-content: flex-end;*/
    flex-direction: column;
    position: relative;
    z-index: 10;
    font-family: "VK Sans Display", sans-serif;
    height: 100%;
    gap: 10px;
    /*gap: 20dvh;*/
}

.server_selector_click_wrapper {
    width: 100dvw;
    height: 100dvh;
    /*background: red;*/
    z-index: 100;
    position: absolute;
    top: 0;
}

.vpn__first_block {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.vpn__first_block > h1 {
    color: #FFF;
    text-align: center;

    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px; /* 135.714% */
    letter-spacing: -0.56px;

}

.vpn__first_block > p {
    color: rgba(255, 255, 255, 0.50);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
}

.vpn__second_block {
    display: flex;
    padding: 12px;
    flex-direction: column;

    align-items: flex-start;
    gap: 10px;
    border-radius: 12px;
    border: 1px solid  rgba(255, 255, 255, 0.15);
    background: #0A0C0D;
    /*max-height: 337px;*/
}

.vpn__second_block__protocols {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 8px;

}

.vpn__second_block__protocols_item {
    display: flex;
    padding: 12px 15px;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;

    /*min-width: 164px;*/
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    background: rgba(255, 255, 255, 0.10);
    flex-wrap: wrap;
    cursor: pointer;
}

.vpn__second_block__protocols__name > span {
    color: rgba(255, 255, 255, 0.50);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.btn_active {
    border-radius: 8px;
    border: 1px solid #FFF;
    background: rgba(255, 255, 255, 0.15);
}

.vpn__second_block__servers {
    display: flex;
    height: 56px;
    padding: 16px;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--Border-colorBorder-05, rgba(255, 255, 255, 0.05));
    background: var(--Fill-ColorFillWhite-10, rgba(255, 255, 255, 0.10));

    cursor: pointer;
}


.vpn__second_block__servers_left {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.vpn__second_block__servers_right {
    display: flex;
    align-items: center;
    gap: 6px;
}

.perfect_ping {
    width: 6px;
    height: 6px;
    border-radius: 30px;
    background-color: rgba(87, 191, 97, 1);
    transition: 0.3s all;
}
.good_ping {
    width: 6px;
    height: 6px;
    border-radius: 30px;
    background-color: rgba(254, 199, 3, 1);
    transition: 0.3s all;
}

.bad_ping {
    width: 6px;
    height: 6px;
    border-radius: 30px;
    background-color: rgba(240, 58, 34, 1);
    transition: 0.3s all;
}

.undefined_ping {
    width: 6px;
    height: 6px;
    border-radius: 30px;
    background-color: rgb(71, 71, 71);
    transition: 0.3s all;
}

.vpn__server_selector {
    /*height: 80dvh;*/
    /*height: 361px;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:  flex-start;
    height: 80%;
    width: 100%;
    position: absolute;
    z-index: 10000!important;
    padding: 16px;
    background-color: rgba(22, 24, 26, 1);
    left: 0;
    bottom: 0;
    border-radius: 28px 28px 0px 0px;
    font-family: "VK Sans Display", sans-serif;

}

.search_server_input {
    display: flex;
    width: 100%;
    padding: 16px 16px 16px 42px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--Border-colorBorderPressed-15, rgba(255, 255, 255, 0.15));
    background: rgba(255, 255, 255, 0.10);
    margin-bottom: 16px;
    color: var(--White, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
    outline: none;
}

.search_server_icon {
    position: absolute;
    left: 12px;
    top: 14px;
}

.vpn__server_selector__h1 {
    margin-bottom: 22px;
}

.vpn__server_selector__h1 > h1 {
    color: #FFF;
    text-align: center;
    font-family: "VK Sans Display", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 116.667% */
    letter-spacing: -0.24px;
}


.vpn__server_selector_wrapper {
    height: 100dvh;
    width: 100dvw;
    /*background: red;*/
    z-index: 1000;
    position: absolute;
    top: 0px;
    left: 0px;
}


.selector_active {
    animation: 0.5s show1 cubic-bezier(0.25, 0.8, 0.25, 1) forwards;
}

.selector_inactive {
    animation: 0.5s hide1 cubic-bezier(0.25, 0.8, 0.25, 1) forwards;
}


@keyframes show1 {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes hide1 {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(100%);
        opacity: 0;
    }
}


.vpn__server_selector__server_list {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 12px;
    border: 1px solid var(--Border-colorBorderPressed-15, rgba(255, 255, 255, 0.15));
    margin-bottom: 16px;
    overflow-y: scroll;
    /*max-height: 361px;*/
}

.vpn__server_selector__server_list_item {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--Border-colorBorderPressed-15, rgba(255, 255, 255, 0.15));
    cursor: pointer;
}

.active_item {
    border-bottom: 1px solid var(--Border-colorBorderPressed-15, rgba(255, 255, 255, 0.15));
    background: var(--Fill-ColorFillWhite-10, rgba(255, 255, 255, 0.10));
}

.vpn__server_selector__server_list_item__text {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.vpn__server_selector__confirm_btn {
    display: flex;
    height: var(--Size-Height-controlHeightXL, 60px);
    padding: 0px 20px;
    align-items: center;
    justify-content: center;
    color: black;
    /*align-self: stretch;*/
    border-radius: var(--BorderRadius-actionBorderRadiusL, 8px);
    background: #FFF;
    width: 100%;
    text-align: center;
}

.vpn__server_selector__server_list_item__ping {
    display: flex;
    align-items: center;
    gap: 8px;
}


.vpn__third_block__download {
    display: flex;
    flex: 1 0 0;
    gap: 8px;
    width: 100%;
}
.vpn__third_block__download > button:nth-child(1){
    display: flex;
    height: 56px;
    width: 100%;
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 8px;
    background: #FFF;

    cursor: pointer;

    color: black;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
}


.vpn__third_block__active_config > button:nth-child(2){
    display: flex;
    width: 56px;
    height: 56px;
    /*padding: 0px 20px;*/
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid var(--Border-colorBorder-05, rgba(255, 255, 255, 0.05));
    background: rgba(255, 255, 255, 0.10);
    cursor: pointer;
}


.vpn__third_block__active_config {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
}


.vpn__third_block__active_config > button:nth-child(1){
    display: flex;
    height: 56px;
    width: 100%;
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 8px;
    background: #FFF;

    cursor: pointer;

    color: black;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
}

.vpn__server_selector__server_list::-webkit-scrollbar {
    width: 5px;
}

.vpn__server_selector__server_list::-webkit-scrollbar-track {
    /*-webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;*/
    background-color: grey;
}

.vpn__server_selector__server_list::-webkit-scrollbar-thumb {
    background-color: #4c4c4c;
}


.loader {
    /*position: absolute;*/
    /*top: calc(50% - 32px);*/
    /*left: calc(50% - 32px);*/
    width: 40px;
    height: 40px;
    border-radius: 300px;
    perspective: 800px;
}

.inner {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.inner.one {
    left: 0%;
    top: 0%;
    animation: rotate-one 1s linear infinite;
    border-bottom: 4px solid #000000;
}

.inner.two {
    right: 0%;
    top: 0%;
    animation: rotate-two 1s linear infinite;
    border-right: 4px solid #000000;
}

.inner.three {
    right: 0%;
    bottom: 0%;
    animation: rotate-three 1s linear infinite;
    border-top: 4px solid #000000;
}

@keyframes rotate-one {
    0% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}

@keyframes rotate-two {
    0% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}

@keyframes rotate-three {
    0% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}


.vpn__copy_tunnel {
    display: flex;
    padding: 16px 12px 12px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid var(--Border-colorBorderPressed-15, rgba(255, 255, 255, 0.15));
    background: var(--Background-colorBgLayout, #0A0C0D);
}

.vpn__copy_h1_text {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.vpn__copy_h1_text > h1 {
    color: var(--Text-colorTextPrimary, #FFF);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.vpn__copy_h1_text > p {
    color: var(--Text-colorTextQuaternary, rgba(255, 255, 255, 0.50));
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
}

.vpn__copy_tunnel__text {
    display: flex;
    height: 48px;
    padding-left: 12px;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    position: relative;
    border-radius: 8px;
    border: 1px solid var(--Border-colorBorderHover-10, rgba(255, 255, 255, 0.10));
    background: var(--Background-colorBgContainer, #16181A);
    /*overflow: hidden;*/
    /*white-space: nowrap;*/

}

.vpn__copy_tunnel__text > p {
    max-width: 85%;
    overflow: hidden;
    white-space: nowrap;
    color: var(--Text-colorTextQuaternary, rgba(255, 255, 255, 0.50));
    text-overflow: ellipsis;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 21px */
}

.vpn__copy_tunnel__text_svg {
    display: flex;
    width: var(--Size-Height-controlHeightL, 48px);
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    right: 0px;
}

.qr_code {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    position: absolute;
    z-index: 100!important;
    padding: 0px 16px 20px 16px;
    background-color: rgba(22, 24, 26, 1);

    left: 0;
    bottom: 0px;

    border-radius: 28px 28px 0px 0px;
    font-family: "VK Sans Display", sans-serif;
    height: 70%;

}

.swipe_item {
    width: 30px;
    height: 5px;
    border-radius: 100px;
    position: absolute;
    top: 10px;
    background-color: #8e8e8e;
}

.scan_qr_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    height: 100%;

}
.scan_qr {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 20px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid var(--Border-colorBorderPressed-15, rgba(255, 255, 255, 0.15));
    background: var(--Fill-ColorFillWhite-05, rgba(255, 255, 255, 0.05));
}

.qr_code__qr_img {

}

.vpn_button_app {
    display: flex;
    width: 100%;
    height: 56px;
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--Border-colorBorder-05, rgba(255, 255, 255, 0.05));
    background: var(--Fill-ColorFillWhite-10, rgba(255, 255, 255, 0.10));
}

@media (max-height: 767px) {
    .vpn__first_block > svg{
        height: 54px;
    }
    .vpn__first_block {
        padding-top: 0px !important;
        gap: 0px;
    }
    .vpn__first_block > h1 {
        font-size: 20px;
    }
    .vpn__first_block > p {
        font-size: 12px;
    }
}

@media (max-height: 783px) {
    .vpn__copy_h1_text > p{
        font-size: 12px;
    }

    .vpn__copy_tunnel__text  > p{
        font-size: 13px;

    }
    .vpn__second_block__protocols_item {

    }
}


.loader {
    /*position: absolute;*/
    /*top: calc(50% - 32px);*/
    /*left: calc(50% - 32px);*/
    width: 40px;
    height: 40px;
    border-radius: 300px;
    perspective: 800px;
}

.inner {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.inner.one {
    left: 0%;
    top: 0%;
    animation: rotate-one 1s linear infinite;
    border-bottom: 4px solid #000000;
}

.inner.two {
    right: 0%;
    top: 0%;
    animation: rotate-two 1s linear infinite;
    border-right: 4px solid #000000;
}

.inner.three {
    right: 0%;
    bottom: 0%;
    animation: rotate-three 1s linear infinite;
    border-top: 4px solid #000000;
}

@keyframes rotate-one {
    0% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}

@keyframes rotate-two {
    0% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}

@keyframes rotate-three {
    0% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}