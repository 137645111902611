
@supports (-webkit-touch-callout: none) {
    .container {
        padding: 16px 16px 16px 16px;
    }
}

.footer {
    height: 72px;
    background-color: black;
    z-index: 100;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    color: var(--Text-colorTextTertiary, rgba(255, 255, 255, 0.70));
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "SFProDisplay";
    font-size: 11px;
    font-style: normal;
    font-weight: 510;
    line-height: 13px; /* 118.182% */
    letter-spacing: 0.06px;
    left: 0px;
    will-change: transform;
    transform: translate(0, 0);

}


.footer_nav_bar_item {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    width: 82px;
    /* height: 72px; */
    position: relative;
}

.footer_nav_bar_item.active {
    color: #FFF;
    transition: 0.4s all;

}

.footer_nav_bar_item.active .active_svg {  
    fill: white;
    fill-opacity: 1;
    
    transition: 0.4s all;
}


a {
    text-decoration: none;
    color: var(--Text-colorTextTertiary, rgba(255, 255, 255, 0.70));
}


div {
    color: white;
}