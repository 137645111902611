.payment_status {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    height: 100%;
    position: absolute;
    z-index: 100000;
    background-color: black;
    padding: 16px;
    top: 0;
    left: 0;

}
.payment_status_content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;

}

.payment_status_content button {
    width: 100%;
    height: 60px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "VK Sans Display", sans-serif;
    font-size: 16px;
    border: 1px solid #FFFFFF0D;

}
.status_content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.status_content_text {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 5px;
}

.status_content_text h1{
    font-family: "VK Sans Display", sans-serif;
    font-weight: 600;
    font-size: 27px;

}

.status_content_text p {
    font-family: "VK Sans Display", sans-serif;
    font-size: 16px;
    opacity: 0.5;
    width: 80%;

}
