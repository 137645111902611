.card {
    display: flex;
    flex-direction: column;
    padding: 12px;
    align-self: flex-start;
    gap: 16px;    
    height: 131px;
    /*width: 100%;*/
    border-radius: 12px;
    border: 1px solid var(--Border-colorBorderPressed-15, rgba(255, 255, 255, 0.15));
    background: var(--Background-colorBgLayout, #0A0C0D);
    flex: 0 0 100%;
    position: relative;
    scroll-snap-align: center;
}

.protect_status{
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 10px;
    animation: blink;
    animation-timing-function: linear;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

.card__content__text {
    display: flex;
    align-items: center;
    gap: 5px;
}

.card__content__text > h1 {
    color: var(--Text-colorTextPrimary, #FFF);
    font-family: "VK Sans Display";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.card__content p {
    color: var(--Text-colorTextQuaternary, rgba(255, 255, 255, 0.50));
    font-family: "VK Sans Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    text-align-last: left;
}

.card__btn {
    display: flex;
    max-width: 160px;
    padding: 8px var(--Space-Padding-paddingL, 16px);
    justify-content: center;
    align-items: center;
    gap: 8px;   
    border-radius: 30px;
    background: var(--Fill-ColorFillWhite-10, rgba(255, 255, 255, 0.10));
    overflow: hidden;
    color: var(--Text-colorTextPrimary, #FFF);
    text-overflow: ellipsis;

    /* Desktop/Body 2/Medium */
    font-family: "VK Sans Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}

.card__logo {
    position: absolute;
    right: 0px;
    bottom: 0px;
}

@keyframes blink {
    50% {
      opacity: 0;
    }
  }