.guide {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
    color: var(--Text-colorTextPrimary, #FFF);
    text-overflow: ellipsis;

    /* Desktop/Body 3/Medium */
    font-family: "VK Sans Display", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}

.text {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;


}

.btn {
    display: flex;
    padding: 8px var(--Space-Padding-paddingL, 16px);
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 30px;
    background: var(--Fill-ColorFillWhite-10, rgba(255, 255, 255, 0.10));
    color: white;
    font-family: "VK Sans Display", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}