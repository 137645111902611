.conect_wallet {
    display: flex;
    align-items: center;   
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    font-family: "VK Sans Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
}

.conect_wallet__main {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.connect_wallet_btns {
    display: flex;
    flex-direction: column;
    gap: 10px;

    position: absolute;
    bottom: 0px;
    width: 100%;
}

.connect_wallet_btns > button{
    display: flex;
    padding: 16px 16px 20px 16px;
    flex-direction: column;    
    gap: 10px;
    cursor: pointer;    
    border-radius: 8px;

    font-family: "VK Sans Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    
}


.connect_wallet_btns > a {
    background: var(--Fill-ColorFillWhite-10, rgb(38, 38, 38));
    color: white;
    display: flex;
    padding: 16px 16px 20px 16px;
    flex-direction: column;    
    gap: 10px;
    cursor: pointer;    
    border-radius: 8px;


}