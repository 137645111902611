.container {
    width: 100%;
    height: 139px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    border-radius: 12px;
    flex: 0 0 95%;

    background: linear-gradient(90deg, #ee8c03, #6d03ee, #ee7903);
    border: 1px solid var(--Border-colorBorderPressed-15, rgba(255, 255, 255, 0.15));
    background-size: 500% 500%;
    -webkit-animation: AnimationName 10s ease-in-out infinite;
    -moz-animation: AnimationName 10s ease infinite;
    animation: AnimationName 10s ease-in-out infinite;
    padding: 12px;
    scroll-snap-align: center;
    position: relative;
    overflow: hidden;
    gap: 16px;


}
.container button {
    display: flex;
    padding: 8px var(--Space-Padding-paddingL, 16px);
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 30px;
    background: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    color: black;
    width: 70%;
}

.container a {
    display: flex;
    padding: 8px var(--Space-Padding-paddingL, 16px);
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 30px;
    background: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    color: black;
    width: 70%;
}

.text {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-family: "VK Sans Display", sans-serif;
    gap:2px;
}

.text h1 {
    color: var(--Text-colorTextPrimary, #FFF);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.text p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: rgba(255, 255, 255, 0.85);
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}


.success_activated {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}
.success_activated h1 {
    font-size: 16px;
}

.success_activated a {
    width: 100%;
    z-index: 123123;
}