.wrapper {
    width: 100%;
    /*min-height: 120px;*/
    display: flex;
    gap: 16px;

    padding: 16px 12px 12px 12px;
    flex-direction: column;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    background: #0A0C0D;
    align-items: flex-start;
    transition: all 0.3s
}
.wrapper > p {
    color: var(--Text-colorTextPrimary, #FFF);
    font-family: "VK Sans Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.wrapper input {
    display: flex;
    width: 100%;
    /*height: 56px;*/
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--Border-colorBorder-05, rgba(255, 255, 255, 0.05));
    background: var(--Fill-ColorFillWhite-10, rgba(255, 255, 255, 0.10));
    color: white;
    font-family: "VK Sans Display", sans-serif;
    transition: all 0.5s;
}

.wrapper input:focus {
    outline: none; /* Убирает стандартную обводку */
    box-shadow: none; /* Убирает тень, если используется */
}