.vpn_card__main {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: center;
    
    gap: 16px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid var(--Border-colorBorderPressed-15, rgba(255, 255, 255, 0.15));
    background: var(--Background-colorBgLayout, #0A0C0D);   
    position: relative;
    
    /*min-height: 324px;*/
    width: 100% !important;
}


.vpn_card__main__second_block { 
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;   
    border-radius: 8px;
    border: 1px solid var(--Border-colorBorderPressed-15, rgba(255, 255, 255, 0.15));
    background: var(--Background-colorBgContainer, #16181A);
}

.vpn_card__main__second_block {
    transition: all 1s;
    animation: plan_fade ease-in-out;
}

@keyframes plan_fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.vpn_card__main__second_block__item {
    display: flex;
    align-items: center;
    gap: 10px;
}

@media (max-width: 495px) {
    .vpn_card__main__second_block__item__text {
        display: block !important;
    }

}
.vpn_card__main__second_block__item__text {
    display: flex;
    align-items: center;
    color: var(--Text-colorTextQuaternary, rgba(255, 255, 255, 0.50));
    font-family: "VK Sans Display";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    gap: 4px;
}

.vpn_card__main__second_block__item__text p:nth-child(1) {
    color: white;
}


.nomis_offer {
    display: flex;
    align-items: center;

    padding: 12px;
    gap: 12px;
    align-self: stretch;

    border-radius: 8px;
    border: 1px solid var(--Border-colorBorderPressed-15, rgba(255, 255, 255, 0.15));
    background: var(--Background-colorBgContainer, #16181A);

    color: #FFF;
    font-family: "VK Sans Display", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 21px */
    position: relative;
}

.ios_loader {
    position: relative;
    width: 40px;
    height: 40px;
}

.ios_loader div {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 4px solid #00ff07;
    border-radius: 50%;
    animation: ios-spin 1.2s linear infinite;
    border-color: #ff0000 transparent transparent transparent;
}

/* Анимация вращения */
@keyframes ios-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}