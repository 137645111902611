.vpn_card__bg {
    position: absolute;
    top: 0px;
}

.vpn_card__main__fist_block {
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
    gap: 16px;
}

.vpn_card__main__fist_block__img_holder {
    min-width: 128px;
    min-height: 128px;
    display: flex;
    width: 128px;
    padding: 7.699px 8.705px 0px 9.295px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--Border-colorBorderPressed-15, rgba(255, 255, 255, 0.15));
    background: var(--Fill-ColorFillWhite-10, rgba(255, 255, 255, 0.10));
    position: relative !important;
    z-index: 2;
}

.vpn_card__main__fist_block__img_holder > img {
    position: absolute;
    bottom: 0px;
}

.vpn_card__main__fist_block__price {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
    gap: 12px;
    z-index: 2;
}

.vpn_card__main__fist_block__price__sale {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 30px;
    background: var(--Fill-ColorFillWhite-15, rgba(255, 255, 255, 0.15));
    width: 56px;
    color: var(--Text-colorTextPrimary, #FFF) !important;
    z-index: 2;

    /* Desktop/Body 3/Medium */
    font-family: "VK Sans Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}  

.vpn_card__main__fist_block__price__text {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.vpn_card__main__fist_block__price__text > h1 {
    color: var(--Text-colorTextTertiary, rgba(255, 255, 255, 0.70));

    /* Desktop/Body 3/Regular */
    font-family: "VK Sans Display";
    font-size: 16px;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px; /* 150% */
}

.vpn_card__main__fist_block__price__text > p{
    color: gray;
    font-family: "VK Sans Display";
    font-size: 52px;
    font-style: normal;
    font-weight: 600;
    line-height: 52px; /* 100% */
    
}

.vpn_card__main__fist_block__price__text > p > span {
    color: white;
    margin-left: 4px;
}

