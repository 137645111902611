.ton_vpn{
    border-radius: 8px;
    background: #FFF;
    color: black;
    display: flex;
    padding: 16px 16px 20px 16px;
    flex-direction: column;    
    gap: 10px;
    cursor: pointer;    
    border-radius: 8px;
    width: 100%;
    display: flex;
    align-items: center;
}