.card_wrapper {
    display: flex;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;   
    border-radius: 12px;
    border: 1px solid var(--Border-colorBorderPressed-15, rgba(255, 255, 255, 0.15));
    background: var(--Background-colorBgLayout, #0A0C0D);
    width: 100%;
    position: relative;
}

/*@media only screen and (max-height: 660px){*/
/*    .card_wrapper {*/
/*        margin-bottom: 72px;*/
/*    }*/
/*}*/
.soon {
    display: flex;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 12px;
    filter: blur(5px);
    width: 100%;
    position: relative;
}

.soon_text {
    position: absolute;
    margin: 0 auto;
    color: #FFF;
    text-align: center;
    font-family: "VK Sans Display";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    z-index: 2;
}
.card_btn {
    display: flex;
    height: 56px;
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: var(--BorderRadius-actionBorderRadiusL, 8px);
    /*background: #FFF;    */
    background-color: #8e8e8e;;
    color: #000 !important;
    font-family: "VK Sans Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
}

.card_content {
    display: flex;
    flex-direction: column;
}

.card_content__balance {
    display: flex;
    align-items: center;
    gap: 8px;

}

.card_content__balance > p {
    color: gray;
    text-align: center;
    font-family: "VK Sans Display";
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 100% */
    letter-spacing: -1.76px;
}

.card_content > p {
    color: var(--Text-colorTextQuaternary, rgba(255, 255, 255, 0.50));
    font-family: "VK Sans Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
}


