.router_page {
    position: relative;
    font-family: "VK Sans Display", sans-serif;
    overflow: clip;
}
.image_wrapper {
    position: relative;
    height: 100vh;
    overflow: hidden; /* Ограничиваем видимость контента */
    background-color: black;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 1); /* Полностью чёрный в начале */
    z-index: 2;
    transition: background 1ms ease-in-out; /* Плавное исчезновение затемнения */
}

.router_content {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative; /* Фиксируем изображение */
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 1;
}

.fist_block_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100dvh -  72px);
    /*position: fixed;*/
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
    z-index: 100;
    /*transform: scale(1);*/
}

.fist_block_wrapper button {
    background-color: rgba(122, 121, 121, 0.33);
    position: absolute;
    bottom: 5px;
    width: 30%;
    height: 30px;
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;

}


.fist_block_wrapper > div > svg {
    transform: rotate(-180deg) !important;
}

.router_content img {
    object-fit: contain;
    /*height: 100dvh;*/
    width: 110dvw;
}

.logo_img {
    width: 80px;
    height: 80px;
    /*position: absolute;*/
    /*transform: translate(50%, 50%);*/
}

/*.container {*/
/*    position: sticky;*/
/*    top: 0%;*/
/*    !*left: 50%;*!*/
/*    !*transform: translate(-50%, -50%);*!*/
/*    text-align: center;*/
/*    background-color: #00ff07;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    flex-direction: column;*/
/*    !*height: 200dvh;*!*/
/*}*/

.row_wrapper {
    /*position: sticky;*/
    top: 50%;
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
}
/*}*/

/*.scroll-text {*/
/*    font-size: 2rem;*/
/*    opacity: 0.5; !* Исходная прозрачность *!*/
/*    transform: scale(1); !* Исходный размер *!*/
/*    transition: transform 0.5s ease, opacity 0.5s ease; !* Плавная анимация *!*/
/*}*/


/*.scroll-text.active {*/
/*    opacity: 1; !* Полная видимость *!*/
/*    transform: scale(1.5); !* Увеличенный размер *!*/
/*}*/

.wrapper1 {
    height: 500dvh;
    font-family: Arial, sans-serif;
    padding: 0;
    /*display: flex;*/
    align-items: center;
    justify-content: center;
    background-color: #000000;
    position: sticky;
    top: 0;
    /*margin-bottom: 600dvh;*/
}


.container1 {
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
    text-align: center;
    position: sticky;
    /*top: 50%;*/
    /*top: 37%;*/
    /*top: 16%;*/
    top: 28%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;

}

.scroll-text {
    font-size: 10px;
    opacity: 0.1;
    transform: scale(1); /* Начальный масштаб */
    transition: transform 0.5s, opacity 0.5s;
    color: white;
    text-align: center;
    width: 100%;
}

.scroll-text h1 {
    font-size: 16px;
}

.scroll-text.active {
    transform: scale(1.4) ; /* Увеличение текста */
    opacity: 1; /* Полная видимость */;

}


.scroll-text_text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: "VK Sans Display", sans-serif;
    gap: 10px;

}
.scroll-text_text h1 {
    /*font-size: 40px;*/
    /*font-family: "Bebas Neue", sans-serif;*/
    /*font-weight: 400;*/
    /*font-style: normal;*/

    font-size: 3em;

    /*text-shadow: 1px 0px 5px rgb(255 125 24);*/
}
.scroll-text:nth-of-type(1) h1 {
    background: linear-gradient(90deg, #ff7a18, #ff0042 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; /* Делаем текст прозрачным */
}

.scroll-text:nth-of-type(2) h1 {
    background: linear-gradient(90deg, #1874ff, #bf0056 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; /* Делаем текст прозрачным */
}

.scroll-text:nth-of-type(3) h1 {
    background: linear-gradient(89deg, #6900e7, #ff6600 47%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; /* Делаем текст прозрачным */
}

.scroll-text:nth-of-type(4) h1 {
    background: linear-gradient(89deg, #0053ff, #00ffd0 47%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; /* Делаем текст прозрачным */
}

.last_block {
    width: 100%;
    /*height: 120dvh;*/
    color: black;
    background: black;
    z-index: 10;
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    /*margin-bottom: 100px;*/
}

.last_block__item {
    position: relative;
    border-color: rgba(255, 255, 255, .05);
    background: radial-gradient(50% 49.99999999999999% at 50% 49.99999999999999%, #16181a 0%, rgba(14, 16, 17, 1) 100%);
    border-radius: 20px;
    overflow: hidden;
    min-height: 231px;
    padding: 32px;
    /*flex: 1 0 0px;*/
}

.last_block__item_text {
    position: absolute;
    z-index: 30;
    bottom: 24px;
    text-shadow: none;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
}

.last_block__item_text h1 {
    font-size: 18px;
}

.last_block__item_text p {
    color: rgb(152 152 152);
    font-size: 16px;
}

.last_block__item img:nth-of-type(1){
    position: absolute;
    bottom: -162px;
    right: -78px;
    top: -174px;
    width: 392px;
    z-index: 1;
    transform: rotate(-90deg);
}

.last_block__item img:nth-of-type(2){
    position: absolute;
    z-index: 2;
    right: 0px;
    /*width: 190px;*/
    height: 150px;
    /*transform: rotate(-90deg);*/
}

.router_card_wrapper {
    /*top: -72px;*/
    height: calc(100dvh - 72px);
    width: 100%;
    background-color: black;
    position: relative;
    z-index: 100;
    padding: 10px;
    /*padding-bottom: 72px;*/
}

.router_card {
    width: 100%;
    height: 100%;
    background-image: url("https://framerusercontent.com/images/otNC3oKhEgy16xf4Zjr8HavS0ok.png?scale-down-to=512");
    overflow: hidden;
    background-position: center;
    background-size: cover;
    border-radius: 16px;
    flex: none;
    gap: unset;
    justify-content: space-between;
    padding: 0 12px 12px;
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

}

@media (max-height: 680px) {
    .router_card img {
        position: relative;
        width: 110% !important;
        left: -2.7% !important;
    }
}

.router_card img {
    position: relative;
    width: 158%;
    left: -22.7%;
}

.router_card h1 {
    font-size: 36px;
}

.router_card_last_block {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
    width: 100%;
    background-color: rgba(255, 255, 255, .05);
    border-radius: 12px;
    border: solid 1px rgba(255, 255, 255, .1);

}
.router_card_last_block button {
    box-shadow: none;
    opacity: 1;
    align-content: center;
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: min-content;
    justify-content: center;
    overflow: hidden;
    padding: 12px 16px;
    position: relative;
    text-decoration: none;
    color: black;
    width: 100%;
    background-color: white;
    will-change: var(--framer-will-change-override, transform);
    border-radius: 12px;
}
