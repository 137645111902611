.banner {
    display: flex;
    flex: 0 0 95%;
    height: 139px;
    padding: 12px;
    align-items: flex-start;
    gap: 16px;
    border-radius: 12px;
    border: 1px solid var(--Border-colorBorderPressed-15, rgba(255, 255, 255, 0.15));
    background: var(--Background-colorBgLayout, #0A0C0D);
    overflow: hidden;
    scroll-snap-align: center;
    position: relative;
}

.banner svg:nth-of-type(1) {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
}

.banner svg:nth-of-type(2) {
    position: absolute;
    z-index: 3;
    right: 0;
    top: 0;
}

.content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    z-index: 4;
    height: 100%;
}

.text {
    display: flex;
    flex-direction: column;
    gap: 2px;
    font-family: "VK Sans Display", sans-serif;
    text-align: left;
}

.text h1 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.text p {
    color: var(--Text-colorTextSecondary, rgba(255, 255, 255, 0.85));
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
}

.content > a {
    display: flex;
    padding: 8px var(--Space-Padding-paddingL, 16px);
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 30px;
    background: linear-gradient(268deg, #FFC700 5.99%, #E43086 114.38%), var(--Fill-ColorFillWhite-10, rgba(255, 255, 255, 0.10));
    text-overflow: ellipsis;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: white;
}