.container {
    height: 90%;
    width: 100dvw;
    background-color: black;
    position: absolute;
    z-index: 10000;
    bottom: 0px;
    /*animation: 0.5s show ease-in;*/
    animation: 0.5s show cubic-bezier(0.25, 0.8, 0.25, 1) forwards;
    border-radius: 30px 30px 0 0;
    padding: 16px;
    font-family: "VK Sans Display", sans-serif;
    /*border-top: 2px solid grey;*/
    /*will-change: transform;*/
    box-shadow: rgb(0, 0, 0) 0px 0px 0px, rgb(255 255 255 / 10%) 0px -2px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.heading {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 10px;
    margin-bottom: 15px;
}

.heading button {
    display: flex;
    padding: 8px var(--Space-Padding-paddingL, 16px);
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 30px;
    background: var(--Fill-ColorFillWhite-10, rgba(255, 255, 255, 0.10));
    overflow: hidden;
    color: var(--Text-colorTextPrimary, #FFF);
    text-overflow: ellipsis;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}


@keyframes show {
    0% {
        transform: translateY(100%);
        /*opacity: 0.8; !* Добавляем плавное появление *!*/
    }
    100% {
        transform: translateY(0);
        /*opacity: 1; !* Конечное состояние *!*/
    }
}

@keyframes hide {
    0% {
        transform: translateY(0);
        opacity: 1; /* Уменьшение прозрачности */
    }
    100% {
        transform: translateY(100%);
        opacity: 0;
    }
}

.show {
    animation: 0.5s show cubic-bezier(0.25, 0.8, 0.25, 1) forwards;
}

.hide {
    animation: 0.5s hide cubic-bezier(0.25, 0.8, 0.25, 1) forwards;
}

.profile {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 16px;
    margin-bottom: 30px;
}
.userData {
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    gap: 7px;
}
.userData > h1 {
    font-size: 18px;
}

.userData > p {
    font-size: 12px;
    color: #acabab;
}
.profile > img {
    width: 60px;
    height: 60px;
    border-radius: 100px;

}

.preference {
    display: flex;
    flex-direction: column;
    gap: 10px;

}
.preference > h1 {
    font-size: 18px;
    color: #8e8e8e;
}

.preference_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}

.preference_item_name {
    display: flex;
    align-items: center;
    gap: 16px;
}



.settings_item_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
}
.settings_item {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 12px;
    border: 1px solid var(--Border-colorBorderPressed-15, rgba(255, 255, 255, 0.15));
    background: var(--Background-colorBgLayout, #0A0C0D);
    padding: 16px 12px 12px 12px;
    gap: 16px;
    position: relative;
    flex-direction: column;
    z-index: 10;
}

.support_code_wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    p:nth-child(2) {
        color: var(--Text-colorTextQuaternary, rgba(255, 255, 255, 0.50));
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.support_btn {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 56px;
    padding: 0px 20px;
    border-radius: 8px;
    background: #FFF;
    width: 100%;
    p {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
    }

}


.support_code {
    display: flex;
    height: 56px;
    padding: 0px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    background: #16181A;
}


.settings_item h1 {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.languages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    position: relative;
}

.selector {
    display: flex;
    height: 56px;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--Border-colorBorder-05, rgba(255, 255, 255, 0.05));
    background: var(--Fill-ColorFillWhite-10, rgba(255, 255, 255, 0.10));
    position: relative;


}
.languages_wrapper {
    position: relative;
    width: 100%;
}

.visual_block_items {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 5px;
    padding: 0px 15px;
}


.visual_block_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
}

.toggle_switch {
    position: relative;
    display: inline-block;
    width: 50px;
    /*height: 25px;*/
}

.toggle_switch_checkbox {
    display: none;
}

.toggle_switch_label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #e4e4e4;
    border-radius: 50px;
    margin: 0;
    background-color: #e4e4e4;
    transition: background-color 0.3s ease-in-out;
}

.toggle_switch_label:active .toggle_switch_button {
    width: 27px;
}

.toggle_switch_button {
    display: block;
    width: 21px;
    height: 21px;
    margin: 2px;
    background: white;
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.toggle_switch_checkbox:checked + .toggle_switch_label {
    background-color: #4cd964;
}

.toggle_switch_checkbox:checked + .toggle_switch_label .toggle_switch_button {
    transform: translateX(25px);
}


.select_lang {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12px;
    border: 1px solid var(--Border-colorBorderPressed-15, rgba(255, 255, 255, 0.15));
    overflow: hidden;
}
.selector_wrapper.hidden {
    display: none;
}

.selector_wrapper {
    border-radius: 28px 28px 0px 0px;
    /*min-height: 281px;*/
    width: 100dvw;
    background: var(--Background-colorBgElevated, #16181A);
    position: absolute;
    bottom: -16px;
    left: -16px;
    padding: 16px 16px 20px 16px;
    z-index: 1000;
}
.selector_active_lang {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.select_lang_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    padding: 16px;
    border-bottom: 1px solid var(--Border-colorBorderPressed-15, rgba(255, 255, 255, 0.15));
    /*background: var(--Fill-ColorFillWhite-10, rgba(255, 255, 255, 0.10));*/
}