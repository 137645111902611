.payments {
    width: 100%;
    /*max-height: 372px;*/
    display: flex;
    padding: 16px 12px 12px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid var(--Border-colorBorderPressed-15, rgba(255, 255, 255, 0.15));
    background: var(--Background-colorBgLayout, #0A0C0D);
    color: var(--Text-colorTextPrimary, #FFF);
    font-family: "VK Sans Display", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: relative;
    /*overflow-x: scroll;*/
    overflow-y: scroll;
}

.heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.heading_close_btn {
    width: 24px;
    height: 24px;
    background: rgba(255, 255, 255, 0.10);
    border-radius: 50px;
    font-size: 18px;
    color: black;
    font-family: "VK Sans Display", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
}

.payments_methods {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    width: 100%;

}

.payment_item {
    display: flex;
    height: 66px;
    padding: 12px 20px 12px 12px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--Border-colorBorder-05, rgba(255, 255, 255, 0.05));
    background: var(--Background-colorBgContainer, #16181A);
}

.payments_name {
    display: flex;
    align-items: center;
    gap: 10px;

}
.payments_name svg:nth-child(2)  {
    width: 40px;
    height: 20px;
}
.payments_name p {
    color: white !important;
}

.payment_item p {
    color: var(--Text-colorTextQuaternary, rgba(255, 255, 255, 0.50));
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.active_payment_item {
    border-radius: 8px;
    border: 1px solid #FFF;
    background: var(--Fill-ColorFillWhite-10, rgba(255, 255, 255, 0.10));
    position: relative;
    transition: all 0.7s;
    cursor: pointer;

}

.active_payment_item_check {
    width: 24px;
    height: 24px;
    position: absolute;
    top: -8px;
    right: -8px;
    background: black;
    /*color: black;*/
    border-radius: 100px;
    font-family: "VK Sans Display", sans-serif;

}

svg {
    shape-rendering: auto;
    image-rendering: auto;
}


.payment_item_soon {
    opacity: 0.5;
    pointer-events: none;

}

.payment_process{
    overflow: hidden;
    z-index: 1000;
    position: fixed;
    width: 100%;
    height: 100%;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    background-color: rgba(0, 0, 0, 0.77);
    backdrop-filter: blur(6px);
    border-radius: 10px;
    color: black;
    padding: 16px 16px 30px 16px
}

.payment_process a {
    font-family: "VK Sans Display", sans-serif;
    color: #5a5a5a;
    /*position: absolute;*/
    /*bottom: 5px;*/
    font-size: 15px;
}

.payment_btn_wrapper a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: var(--Size-Height-controlHeightXL, 60px);
    padding: 0px 20px;
    border-radius: 8px;
    text-align: center;
    border: 1px solid var(--Border-colorBorder-05, rgba(255, 255, 255, 0.05));
    background: #FFF;
    color: var(--Text-colorTextPrimary, #000000);
    font-family: "VK Sans Display", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
}
.payment_process button{
    width: 100%;
    height: var(--Size-Height-controlHeightXL, 60px);
    padding: 0px 20px;
    border-radius: 8px;
    text-align: center;
    border: 1px solid var(--Border-colorBorder-05, rgba(255, 255, 255, 0.05));
    background: var(--Fill-ColorFillWhite-10, rgb(35, 36, 38));
    color: var(--Text-colorTextPrimary, #FFF);
    font-family: "VK Sans Display", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
}

.loader_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 22px;
}

.payment_process_cards {
    width: 100%;
    display: flex;
    height: 107px;
    padding: 12px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    background: var(--Background-colorBgContainer, #16181A);
}

.payment_process_cards_items {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11px;
}


.payment_process_cards_item {
    border-radius: 6.445px;
    width: 75.194px;
    height: 51.561px;
    flex-shrink: 0;
    background: #2D2F30;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader {
    width: 123px;
    height: 123px;
    border: 10px solid #f3f3f3;
    border-top: 10px solid #f59003;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    z-index: 1000000;
}


.payment_btn_wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


.processing_company {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.87);
    backdrop-filter: blur(1px);
    left: 0;
    top: 0;
    z-index: 1000;
    border-radius: 12px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
}

.processing_company_btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

}

.processing_company_btns button {
    width: 200px;
    height: 50px;
    border-radius: 12px;
    background-color: white;
}

