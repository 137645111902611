.card {
    display: flex;
    flex-direction: column;
    padding: 12px;
    align-self: flex-start;
    justify-content: space-between;
    gap: 16px;    
    height: 139px;
    /*width: 100%;*/
    border-radius: 12px;
    border: 1px solid var(--Border-colorBorderPressed-15, rgba(255, 255, 255, 0.15));
    background: var(--Background-colorBgLayout, #0A0C0D);

    position: relative;

    scroll-snap-align: center;
    overflow: hidden;
}

.card > img {
    position: absolute;
    inset: 0;
    z-index: -1;
}

.privatePass {
    background: linear-gradient(201deg, #524741, #b98412e3, #474441);
    background-size: 200% 200%;


    -webkit-animation: AnimationName 5s ease infinite;
    -moz-animation: AnimationName 5s ease infinite;
    animation: AnimationName 5s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:51% 0%}
    50%{background-position:50% 100%}
    100%{background-position:51% 0%}
}
@-moz-keyframes AnimationName {
    0%{background-position:51% 0%}
    50%{background-position:50% 100%}
    100%{background-position:51% 0%}
}
@keyframes AnimationName {
    0%{background-position:51% 0%}
    50%{background-position:50% 100%}
    100%{background-position:51% 0%}
}

.protect_status{
    width: 8px;
    height: 8px;
    background-color: #36D750;
    border-radius: 10px;
    animation: blink;
    animation-timing-function: linear;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

.card__content__text {
    display: flex;
    align-items: center;
    gap: 5px;
}

.card__content__text > h1 {
    color: var(--Text-colorTextPrimary, #FFF);
    font-family: "VK Sans Display";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.card__content p {
    color: var(--Text-colorTextQuaternary, rgba(255, 255, 255, 0.50));
    font-family: "VK Sans Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    text-align-last: left;
}


@media (max-width: 361px) {

    .card__content__text > h1 {
        font-size: 16px !important;
    }

    .card__content p {
        font-size: 15px !important;
    }

}

.card__btn {
    display: flex;
    max-width: 123px;
    padding: 8px var(--Space-Padding-paddingL, 16px);
    justify-content: center;
    align-items: center;
    gap: 8px;   
    border-radius: 30px;
    background: var(--Fill-ColorFillWhite-10, rgba(255, 255, 255, 0.10));
    overflow: hidden;
    color: var(--Text-colorTextPrimary, #FFF);
    text-overflow: ellipsis;

    /* Desktop/Body 2/Medium */
    font-family: "VK Sans Display";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}

.card__logo {
    position: absolute;
    right: 0px;
    bottom: 0px;
}

@keyframes blink {
    50% {
      opacity: 0;
    }
  }



